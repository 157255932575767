<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FDCE48"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FDCE48" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>
          <span style="color: #000">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap justify-center>
      <v-flex xs12 text-left>
        <span class="itemHeading"> Edit Profile </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center py-4>
      <v-flex xs12 lg10>
        <v-layout wrap justify-start py-4>
          <v-flex xs6 text-left px-2>
            <v-avatar color="#FFF9C4" size="100">
              <v-img v-if="profilePic" :src="profilePic">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="#FDCE48"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-img
                v-if="!profilePic && storage.photo"
                :src="mediaURL + storage.photo"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="#FDCE48"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-avatar>
          </v-flex>
          <v-flex xs6 text-left align-self-center px-2>
            <v-btn
              tile
              color="#FDCE48"
              light
              :ripple="false"
              depressed
              class="itemValue"
              @click="$refs.uploadProfilePic.click()"
            >
              Change Photo
            </v-btn>
            <input
              v-show="false"
              accept="image/*"
              ref="uploadProfilePic"
              type="file"
              @change="uploadProfilePic"
            />
          </v-flex>
        </v-layout>
        <v-layout wrap justify-start>
          <v-flex xs12 sm6 text-left px-2>
            <span class="itemValue">First Name</span>
            <v-text-field
              ref="firstname"
              color="#FDCE48"
              placeholder="First Name"
              dense
              v-model="firstname"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 text-left px-2>
            <span class="itemValue">Last Name</span>
            <v-text-field
              ref="lastname"
              color="#FDCE48"
              placeholder="Last Name"
              dense
              v-model="lastname"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 text-left px-2>
            <span class="itemValue">Username</span>
            <v-text-field
              ref="username"
              color="#FDCE48"
              placeholder="Username"
              dense
              v-model="username"
            ></v-text-field>
          </v-flex>
          <!-- <v-flex xs12 sm6 text-left px-2>
            <span class="itemValue">Password</span>
            <v-text-field
              ref="password"
              color="#FDCE48"
              placeholder="Password"
              type="password"
              dense
              v-model="password"
            ></v-text-field>
          </v-flex> -->
          <v-flex xs12 sm6 text-left px-2>
            <span class="itemValue">Phone No</span>
            <v-text-field
              ref="phone"
              color="#FDCE48"
              placeholder="Phone No"
              type="number"
              dense
              v-model="phone"
              :error-messages="msg_phone"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 text-left px-2>
            <span class="itemValue">Email Id</span>
            <v-text-field
              ref="email"
              color="#FDCE48"
              placeholder="Email"
              dense
              v-model="email"
              :error-messages="msg_email"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 text-left px-2>
            <span class="itemValue">Country</span>
            <v-text-field
              ref="country"
              color="#FDCE48"
              placeholder="Country"
              dense
              v-model="country"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 text-left px-2>
            <span class="itemValue">Location</span>
            <v-text-field
              ref="locationname"
              color="#FDCE48"
              placeholder="Location"
              dense
              v-model="locationname"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 text-left px-2>
            <span class="itemValue">Gender</span>
            <v-select
              ref="gender"
              color="#FDCE48"
              placeholder="Gender"
              dense
              v-model="gender"
              :items="genders"
              item-text="name"
              item-value="value"
              item-color="#FDCE48"
            >
              <template v-slot:item="{ item }">
                <span class="text-left">
                  {{ item.name }}
                </span>
              </template>
            </v-select>
          </v-flex>
          <v-flex xs12 sm6 text-left px-2>
            <span class="itemValue">User Type</span>
            <v-select
              ref="userType"
              color="#FDCE48"
              placeholder="User Type"
              dense
              v-model="userType"
              :items="userTypes"
              item-text="name"
              item-value="value"
              item-color="#FDCE48"
            >
              <template v-slot:item="{ item }">
                <span class="text-left">
                  {{ item.name }}
                </span>
              </template>
            </v-select>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs6 sm4 xl3 px-2>
            <v-btn
              block
              tile
              outlined
              color="#FF1313"
              light
              :ripple="false"
              depressed
              @click="closeDialoge"
              class="itemValue"
            >
              Cancel
            </v-btn>
          </v-flex>
          <v-flex xs6 sm4 xl3 px-2>
            <v-btn
              block
              tile
              color="#FDCE48"
              light
              :ripple="false"
              depressed
              @click="validateInput"
              class="itemValue"
            >
              Edit Profile
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["storage"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,

      firstname: null,
      lastname: null,
      username: null,
      // password: null,
      phone: null,
      email: null,
      country: null,
      userType: "Admin",
      locationname: null,
      gender: "Male",
      profilePicImage: null,
      profilePic: null,
      genders: [
        { name: "Male", value: "Male" },
        { name: "Female", value: "Female" },
        { name: "Others", value: "Others" },
      ],
      userTypes: [
        { name: "User", value: "User" },
        { name: "Admin", value: "Admin" },
      ],
      msg_email: null,
      msg_phone: null,
    };
  },
  beforeMount() {
    this.firstname = this.storage.firstname;
    this.lastname = this.storage.lastname;
    this.username = this.storage.username;
    this.phone = this.storage.phone;
    this.email = this.storage.email;
    this.country = this.storage.country;
    this.userType = this.storage.role;
    this.locationname = this.storage.locationname;
    this.gender = this.storage.gender;
  },
  watch: {
    phone() {
      axios({
        method: "POST",
        url: "/user/check/phone",
        data: {
          phone: this.phone,
          id: this.storage._id,
        },
      })
        .then((response) => {
          if (response.data.status == false) this.msg_phone = response.data.msg;
          else this.msg_phone = null;
        })
        .catch((err) => {
          this.msg = err;
          this.ServerError = true;
        });
    },
    email() {
      axios({
        method: "POST",
        url: "/user/check/email",
        data: {
          email: this.email,
          id: this.storage._id,
        },
      })
        .then((response) => {
          if (response.data.status == false) this.msg_email = response.data.msg;
          else this.msg_email = null;
        })
        .catch((err) => {
          this.msg = err;
          this.ServerError = true;
        });
    },
  },
  methods: {
    closeDialoge() {
      this.$emit("stepper", {
        type: "Edit",
        getData: false,
        editProfile: false,
      });
    },

    uploadProfilePic(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      if (files[0].size > 2097152) {
        this.msg = "File Should be less than 2MB";
        this.showSnackBar = true;
        return;
      }
      this.profilePicImage = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.profilePic = e.target.result;
      };
      reader.readAsDataURL(files[0]);
    },
    validateInput() {
      if (!this.firstname) {
        this.msg = "Please Provide First Name";
        this.showSnackBar = true;
        return;
      } else if (!this.lastname) {
        this.msg = "Please Provide Last name";
        this.showSnackBar = true;
        return;
      } else if (!this.username) {
        this.msg = "Please Provide Username";
        this.showSnackBar = true;
        return;
      }
      // else if (!this.password) {
      //   this.msg = "Please Provide Password";
      //   this.showSnackBar = true;
      //   return;
      // }
      else if (!this.phone) {
        this.msg = "Please Provide Phone Number";
        this.showSnackBar = true;
        return;
      } else if (!this.email) {
        this.msg = "Please Provide Email";
        this.showSnackBar = true;
        return;
      } else if (!this.country) {
        this.msg = "Please Provide Country";
        this.showSnackBar = true;
        return;
      } else {
        this.editProfile();
      }
    },
    editProfile() {
      var userData = {};
      userData["firstname"] = this.firstname;
      userData["lastname"] = this.lastname;
      userData["username"] = this.username;
      // userData["password"] = this.password;
      userData["phone"] = this.phone;
      userData["email"] = this.email;
      userData["country"] = this.country;
      userData["locationname"] = this.locationname;
      userData["gender"] = this.gender;
      userData["role"] = this.userType;
      axios({
        method: "post",
        url: "/user/Edit",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: userData,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Profile Updated";
            this.showSnackBar = true;
            if (this.profilePicImage) {
              this.uploadPic();
              return;
            }
            this.$emit("stepper", {
              type: "Edit",
              getData: true,
              editProfile: false,
            });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },

    uploadPic() {
      var formData = new FormData();
      formData.append("photo", this.profilePicImage);
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/User/photo",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: formData,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            // store.commit("changePicture", response.data.image);
            this.profilePicImage = null;
            this.profilePic = null;
            this.msg = "Profile Updated";
            this.showSnackBar = true;
            this.$emit("stepper", {
              type: "Edit",
              getData: true,
              editProfile: false,
            });
          }
          this.msg = response.data.msg;
          this.showSnackBar = true;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>