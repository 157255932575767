<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FDCE48"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FDCE48" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #000">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-dialog
      v-model="editProfileDialog"
      persistent
      :width="
        $vuetify.breakpoint.name == 'xs' ||
        $vuetify.breakpoint.name == 'sm' ||
        $vuetify.breakpoint.name == 'md'
          ? '100vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '75vw'
          : '50vw'
      "
    >
      <v-card>
        <v-layout wrap justify-center pa-2>
          <v-flex xs12>
            <EditProfile v-bind:storage="userData" @stepper="winStepper" />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="changePasswordDialog"
      persistent
      :width="
        $vuetify.breakpoint.name == 'xs'
          ? '100vw'
          : $vuetify.breakpoint.name == 'sm'
          ? '50vw'
          : $vuetify.breakpoint.name == 'md' || $vuetify.breakpoint.name == 'lg'
          ? '40vw'
          : '25vw'
      "
    >
      <v-card>
        <v-layout wrap justify-center pa-4>
          <v-flex xs12>
            <ChangePassword v-bind:storage="userData" @stepper="winStepper" />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout wrap justify-center>
      <v-flex xs12 text-left>
        <span class="itemHeading"> Profile </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start pt-4>
      <v-flex xs12 text-left>
        <v-avatar color="#FFF9C4" size="100">
          <v-img v-if="userData.photo" :src="mediaURL + userData.photo">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="#FDCE48"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-avatar>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center py-4>
      <v-flex xs12>
        <v-layout wrap justify-start>
          <v-flex v-if="userData.firstname" xs12 sm4 text-left py-2>
            <span class="itemKey">Name</span> <br />
            <span class="itemValue"
              >{{ userData.firstname }} {{ userData.lastname }}</span
            >
          </v-flex>
          <v-flex v-if=" userData.username" xs12 sm4 text-left py-2>
            <span class="itemKey">Username</span> <br />
            <span class="itemValue">{{ userData.username }}</span>
          </v-flex>
            <v-flex v-if="userData.email" xs12 sm4 text-left py-2>
            <span class="itemKey">Email</span> <br />
            <span class="itemValue">{{ userData.email }}</span>
          </v-flex>
          <v-flex v-if="userData.phone" xs12 sm4 text-left py-2>
            <span class="itemKey">Phone</span> <br />
            <span class="itemValue">{{ userData.phone }}</span>
          </v-flex>
        
          <v-flex v-if="userData.country" xs12 sm4 text-left py-2>
            <span class="itemKey">Country</span> <br />
            <span class="itemValue">{{ userData.country }}</span>
          </v-flex>
          <v-flex v-if="userData.locationname" xs12 sm4 text-left py-2>
            <span class="itemKey">Location</span> <br />
            <span class="itemValue">{{ userData.locationname }}</span>
          </v-flex>
          <v-flex v-if="userData.gender" xs12 sm4 text-left py-2>
            <span class="itemKey">Gender</span> <br />
            <span class="itemValue">{{ userData.gender }}</span>
          </v-flex>
          <v-flex xs12 sm4 text-left py-2>
            <span class="itemKey">Account Created</span> <br />
            <span class="itemValue">{{
              formatDate(userData.create_date)
            }}</span>
          </v-flex>
          <!-- <v-flex xs12 sm4 text-left py-2>
            <span class="itemKey">Last Active</span> <br />
            <span class="itemValue">{{ formatDate(userData.lastactiontime) }}</span>
          </v-flex> -->
        </v-layout>
      </v-flex>
    </v-layout>
    <!-- <v-layout wrap justify-center>
      <v-flex xs12 sm3 xl2 px-2>
        <v-btn
          block
          tile
          color="#FDCE48"
          :ripple="false"
          depressed
          @click="editProfileDialog = true"
          class="itemValue"
        >
          Edit Profile
        </v-btn>
      </v-flex>

      <v-flex xs12 sm3 xl2 px-2>
        <v-btn
          block
          tile
          outlined
          color="#FDCE48"
          :ripple="false"
          depressed
          @click="changePasswordDialog = true"
          class="itemValue"
        >
          <span style="color: #000000"> Change Password </span>
        </v-btn>
      </v-flex>
    </v-layout> -->
  </div>
</template>
<script>
import axios from "axios";
import store from "./../../store";
import EditProfile from "./editProfile";
import ChangePassword from "./changePassword";
export default {
  components: {
    EditProfile,
    ChangePassword,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      editProfileDialog: false,
      changePasswordDialog: false,
    };
  },
  beforeMount() {
    // this.getData();
  },
  computed: {
    userData() {
      return store.state.userData.user;
    },
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/me",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.userData = response.data.data;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    winStepper(windowData) {
      if (windowData.type == "Edit") {
        if (windowData.getData) this.getData();
        this.editProfileDialog = windowData.editProfile;
      } else if (windowData.type == "Password") {
        if (windowData.status) {
          this.msg = "Password Changed";
          this.showSnackBar = true;
        }
        this.changePasswordDialog = windowData.changePassword;
      }
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      day = day < 10 ? "0" + day : day;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>